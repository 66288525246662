import React from "react"
import imageUrl from "../../helpers/flotiqImage";

const ServiceFeature = ({feature}) => {
    return (
        <div className="service-feature">
            <img className="service-feature__icon" alt={feature.description} src={imageUrl(feature.icon)}  />
            <div className="service-feature__info">
                <h4 className="service-feature__name">
                    {feature.name}
                </h4>
                <div className="service-feature__description" dangerouslySetInnerHTML={{__html: feature.description}}></div>
            </div>
        </div>
    )
}

export default ServiceFeature