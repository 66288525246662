import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import ServiceIntroSection from "../sections/service/service-intro-section";
import ServiceAboutSection from "../sections/service/service-about-section";
import HowWeWrokSection from "../sections/how-we-work-section";
import ServicesAdvantagesSection from "../sections/services/services-advantages-section";
import CTASection from "../sections/cta-section";
import ServiceProjectsSection from "../sections/service/service-projects-section";
import ServiceTestimonialsSection from "../sections/service/service-testimonials-section";

export default ({data}) => {
    const service = data.service;
    return (
        <Layout>
            <SEO title={service.name}/>
            <ServiceIntroSection service={service}/>
            <ServiceAboutSection service={service}/>
            <HowWeWrokSection/>
            <ServicesAdvantagesSection/>
            <ServiceProjectsSection projects={service.relatedProjects.filter(project => project.isPublic && project.isCaseStudy)}/>
            <ServiceTestimonialsSection/>
            <CTASection/>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String) {
        service: codewaveService( slug: { eq: $slug }) {
            id,
            slug,
            name,
            shortDescription,
            image {
                id
                extension
            },
            fullDescriptionHeader,
            fullDescription,
            features {
                description
                name
                icon {
                    id
                    extension
                }
            },
            relatedTechnologies {
                name
                isPublic
                logo {
                    extension
                    id
                }
            },
            relatedProjects {
                name
                slug
                shortDescription
                isPublic
                isCaseStudy
                tags {
                    name
                    slug
                }
                projectTileImage {
                    extension
                    id
                }
            }
        }
    }
`