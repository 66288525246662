import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import ServiceFeature from "../../components/service/service-feature";
import ServiceTechnology from "../../components/service/service-technology";

const ServiceAboutSection = ({service}) => {
    const data = useStaticQuery(graphql`
        query {
            serviceAboutBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.svg" }) {
                publicURL
            }
        }
    `);

    let serviceAboutSectionStyle = {
        backgroundImage: `url(${data.serviceAboutBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={serviceAboutSectionStyle}>
            <div className="cw-container content-section service-about-section">
                <div className="service-about-section__description-column">
                    <h2 className="section-header section-header--colored">{service.fullDescriptionHeader}</h2>
                    <div className="section-description" dangerouslySetInnerHTML={{__html: service.fullDescription}}></div>
                    <div className="service-about-section__service-technologies">
                        {service.relatedTechnologies 
                            && service.relatedTechnologies
                                .filter(technology => technology.isPublic)
                                .map((technology, index) => <ServiceTechnology key={index} technology={technology} />)}
                    </div>
                </div>
                <div className="service-about-section__features-column">
                    {service.features && service.features.map((feature, index) => <ServiceFeature key={index} feature={feature} />)}
                </div>
            </div>
        </section>
    )
}

export default ServiceAboutSection