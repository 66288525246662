import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import ClientsLogotypes from "../../components/clients-logotypes";
import TestimonialsSection from "../testimonials-section";

const ServiceTestimonialsSection = () => {
    const data = useStaticQuery(graphql`
        query {
            serviceAboutBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.svg" }) {
                publicURL
            }
        }
    `);

    let serviceAboutSectionStyle = {
        backgroundImage: `url(${data.serviceAboutBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid content-section" style={serviceAboutSectionStyle}>
            <h2 className="section-header text-center">Testimonials</h2>
            <div className="cw-container service-testimonials-section">
                <div className="service-testimonials-section__testimonials-column">
                    <TestimonialsSection sliderSettings={{
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }}/>

                </div>
                <div className="service-testimonials-section__clients-logotypes-column">
                    <ClientsLogotypes/>
                </div>
            </div>
        </section>
    )
}

export default ServiceTestimonialsSection