import React from "react"
import imageUrl from "../../helpers/flotiqImage";
// import imageUrl from "../../helpers/flotiqImage";

const ServiceTechnology = ({technology}) => {
    return (
        <div className="service-technology">
            <img className="service-technology__icon" alt={technology.name} src={imageUrl(technology.logo)} />
            <h4 className="service-technology__name">
                {technology.name}
            </h4>
        </div>
    )
}

export default ServiceTechnology