import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import SuccessStoryTile from "../../components/success-story-tile";

const ServiceProjectsSection = ({projects}) => {
    const data = useStaticQuery(graphql`
        query {
            serviceProjectsBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.jpg" }) {
                publicURL
            }
        }
    `);

    let serviceProjectsSectionStyle = {
        backgroundImage: `url(${data.serviceProjectsBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={serviceProjectsSectionStyle}>
            <div className="cw-container content-section service-projects-section">
                <div className="service-projects-section__description-column">
                    <h2 className="section-header section-header--colored">Projects</h2>
                    <p className="section-description">See how an idea can be transformed into a wonderful initiative through the incredible power of digital communication.</p>
                </div>
                <div className="service-projects-section__projects-tiles-column">
                    {projects && projects.slice(0,3).map((project, index) => <SuccessStoryTile key={index} project={project} />)}
                </div>
            </div>
        </section>
    )
}

export default ServiceProjectsSection