import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import imageUrl from "../../helpers/flotiqImage";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const ServiceIntroSection = ({service}) => {
    const data = useStaticQuery(graphql`
        query {
            introBG: file(relativePath: { eq: "backgrounds/cdwv-bg-intro-alt.svg" }) {
                publicURL
            }
        }
    `);

    let serviceIntroSectionStyle = {
        backgroundImage: `url(${data.introBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={serviceIntroSectionStyle}>
            <div className="intro intro--service">
                <div className="intro-cta intro-cta--service">
                    <h1 className="intro-cta__header intro-cta__header--colored">{service.name}</h1>
                    <div className="intro-cta__description" dangerouslySetInnerHTML={{__html: service.shortDescription}}></div>
                    <div className="intro-cta__buttons">
                        <AniLink duration={.5} fade to="/contact" className="btn">Get in Touch</AniLink>
                    </div>
                </div>
                <div className="service-intro-image">
                    <img className="service-intro-image__image" alt={service.name} src={imageUrl(service.image)}/>
                </div>
            </div>
        </section>
    )
}

export default ServiceIntroSection